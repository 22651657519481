<template lang="pug">
.feature-recommender
  template(v-if="onboardingFinished || !isUserRequestedAi")
    .d-flex.w-100.align-items-center
      uil-arrow-up-right(size="22px")
      om-heading.ml-2(h4) {{ $t('onboarding.tactic.title') }}
    skeleton.mt-2(v-if="loading" :rows="1" :cols="1")
    template(v-else)
      img.my-4.w-100.image(
        :alt="$t(activeBanner.title)"
        :src="activeBanner.img"
        :class="clickableClasses"
        @click="openPublicTactic"
      )
      .d-flex.mb-4
        om-heading(h5 :class="clickableClasses" @click.native="openPublicTactic") {{ $t(activeBanner.title) }}
      om-body-text.gray-600(v-if="isAI" bt400md) {{ $t(activeBanner.description) }}
      om-body-text.gray-600(v-else bt400md v-html="activeBanner.description")
      .d-flex.justify-content-end.mt-5(v-if="isAI")
        om-button.mr-2(ghost @click="openPublicTactic") {{ $t('learnMore') }}
        om-button.requestAccess(secondary :loading="isLoading" @click="showBooking") {{ $t('onboarding.tactic.requestAccess') }}
      .d-flex.justify-content-end.mt-5(v-else)
        om-button.mr-2(ghost icon="book-alt" @click="openPublicLibrary") {{ $t('onboarding.tactic.moreTactics') }}
        om-button.learnMore(secondary @click="openPublicTactic") {{ $t('learnMore') }}
  .d-block(v-else)
    img.mb-4.w-100(
      alt="Tactic placeholder"
      src="@/assets/admin/img/onboarding/dashboard/tactic_placeholder.png"
    )
    .text-center.px-2.feature-recommender__placeholder-text {{ $t('onboarding.tactic.placeholder') }}
  Book(
    :about="about"
    @track="onTrack($event)"
    :type="getOptimizationType(about)"
    @close="openDetailsModal"
  )
</template>

<script>
  import { UilArrowUpRight } from '@iconscout/vue-unicons';
  import { track } from '@/services/xray';
  import { mapGetters, mapActions } from 'vuex';
  import { getRandomIntInclusive } from '@/util';
  import Skeleton from '@/components/TemplateChooser/components/Skeleton.vue';
  import { slugify, tacticLibraryUrl } from '@/utils/tactics';
  import commonFlowsMixin from '@/mixins/commonFlows';

  const AI_BANNERS = [
    'smart_popup',
    'smart_a_b_test',
    'smart_personalization',
    'product_page_optimizer',
  ];

  export default {
    name: 'FeatureRecommender',
    components: {
      UilArrowUpRight,
      Badge: () => import('@/components/Badge.vue'),
      Skeleton,
    },
    mixins: [commonFlowsMixin],

    props: {
      onboardingFinished: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      isAI: false,
      banners: [],
      selectedBanner: '',
      loading: false,
      tactic: {},
      about: 'sab',
    }),

    computed: {
      ...mapGetters(['getRecommendedTactics', 'isUserRequestedAi']),

      clickableClasses() {
        return 'cursor-pointer clickable-title';
      },

      activeBanner() {
        if (this.isAI) {
          const tactic = this.selectedBanner;
          return {
            tactic,
            img: require(`@/assets/admin/img/onboarding/dashboard/tactic_${tactic}.png`),
            title: `onboarding.tactic.${tactic}.title`,
            description: `onboarding.tactic.${tactic}.description`,
            url: `onboarding.tactic.${tactic}.url`,
          };
        }

        const tactic = this.getRecommendedTactics.find((t) => t.name.en === this.selectedBanner);
        const tacticName = tactic.name[this.$i18n.locale];
        const url = `${tacticLibraryUrl[this.$i18n.locale]}/${slugify(tacticName)}-${tactic._id}`;
        return {
          title: tactic.name[this.$i18n.locale],
          description: tactic.usage[this.$i18n.locale],
          img: tactic.svgPreviews.length ? tactic.svgPreviews[0] : tactic.thumbnail.previews[0],
          url,
        };
      },
    },

    async created() {
      if (!this.isUserRequestedAi) {
        this.banners = AI_BANNERS;
        this.isAI = true;
      } else {
        this.loading = true;
        await this.fetchRecommendedTactics();
        this.banners = this.getRecommendedTactics
          .filter((t) => t?.thumbnail?.previews?.[0] ?? t?.svgPreviews?.[0])
          .map((t) => t.name.en);
        this.loading = false;
      }
      this.setSelectedBanner();
      track('dashboard_recommender_loaded', {
        tactic: this.selectedBanner,
      });
    },
    methods: {
      ...mapActions(['fetchRecommendedTactics']),

      setSelectedBanner() {
        if (this.isAI && Math.random() > 0.5) {
          this.selectedBanner = this.banners[0];
        } else if (this.isAI) {
          this.selectedBanner = this.banners[getRandomIntInclusive(1, this.banners.length - 1)];
        } else {
          this.selectedBanner = this.banners[getRandomIntInclusive(0, this.banners.length - 1)];
        }
      },

      openPublicLibrary() {
        track('dashboard_recommender_checkMore', {
          tactic: this.selectedBanner,
        });
        const listingPageUrl = tacticLibraryUrl[this.$i18n.locale];
        window.open(listingPageUrl, '_blank');
      },

      openPublicTactic() {
        track('dashboard_recommender_readMore', {
          tactic: this.selectedBanner,
        });
        if (this.isAI) {
          this.$router.push({ name: 'ai_features' });
          return;
        }
        window.open(this.activeBanner.url, '_blank');
      },
      showBooking() {
        this.$modal.show('new-campaign-flow-book');
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .feature-recommender
    width: 18.75rem
    height: 100%
    .body-text
      line-break: auto

    .badge
      background: #f1f2f4 !important
      span
        color: #505763
        font-weight: 400
        font-size: 14px
        line-height: 24px

    &__placeholder-text
      line-height: 28px
      color: #8F97A4
      font-size: 1rem
      font-weight: 500

    .image
      height: 100%
      object-fit: contain

  .clickable-title
    transition: color 200ms ease-in-out
    &:hover
      color: $om-orange-500
</style>
